import {useEffect, useState, useRef} from 'react'; 
import Pagination from 'rc-pagination'

const _locale = { 

  items_per_page: 'Items Per Page',
  jump_to: 'Jump To',
  jump_to_confirm: 'Jump To Confirm',
  page: 'Page',

  prev_page: 'Previous Page',
  next_page: 'Next Page',
  prev_5: 'Previous 5 Pages',
  next_5: 'Next 5 Pages',
  prev_3: 'Previous 3 Pages',
  next_3: 'Next 3 Pages',
  page_size: 'Page Size',

}

export default function Paginator({itemsPerPage, pageRangeDisplayed, totalCount,  search}) {

  const handlePageClick =(current, pageSize) => {
    search.setPage(current)
    if (initialNav){
      initialNav.classList.remove('rc-pagination-item-active');
      setInitialNav(null);
    }
    window.scrollTo({
      top: 0,
    });
  }

  const [initialNav, setInitialNav] = useState(null);
  const currentPage = search.search.page ? search.search.page : 1;

  useEffect(( ) => {
    //set initial active page after item renders for the first time 
    //since on change is the only thing that seems to  trigger which item is 
    //
    //updated to reflect the fact that some pages use two instances of the paginator and only the first one
    //will get the active page class added
    let nav = document.querySelectorAll(`.lex-cms-pagination .rc-pagination-item-${currentPage }`)  
    if (nav?.length > 0) {
      nav.forEach((n, i) => {
        n.classList.add('rc-pagination-item-active');
      })
       setInitialNav(nav[0]);
    }
   }, [])

  return (
      <Pagination 
        className='lex-cms-pagination'
        current={currentPage}
        onChange={handlePageClick}
        defaultPageSize={itemsPerPage}
        locale={_locale }
        total={totalCount}

       />
     )

}
