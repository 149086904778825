import { useState, useEffect } from "react";
import ProductAttributeFilter from "./product_attribute_filter";
import Image from "next/image";

const ProductFilterAccordion = ({ attribute, searchState, grouped, initialExpandState, shouldUpdate}) => {
  const [isActive, setIsActive] = useState(initialExpandState);
  const [listExpanded, setListExpanded] = useState(false) 

  useEffect(( ) => {
   setIsActive(initialExpandState)
  }, [shouldUpdate])
  const active = (typeof isActive === 'undefined' ) ? initialExpandState : isActive;

  const header = (
    <div onClick={() => setIsActive(!isActive)}>
      <div className={"lexcms-product-search-filter-name-and-toggle"}>
        <h3 className="lexcms-product-search-filter-name">{attribute.name}</h3>
        <div
          className={
            "lexcms-product-search-filter-toggle" +
            (!active ? "" : " active-accordion-toggle")
          }
        ></div>
      </div>
    </div>
  );
  return (
    <div className="lexcms-product-search-filter-accordion">
      {grouped ? (
        <div className="lex-cms-grouped-attribute-list">{attribute.name}</div>
      ) : (
        header
      )}
      <div
        className={
          "lexcms-product-search-filter-value-container lexcms-mobile-filter-value-container" +
          (!active ? "" : " active-accordion-container") +
          (grouped ? " grouped" : "")
        }
      >
        <form className="lexcms-product-search-filter-form">
          {attribute?.product_attribute_values?.map((value, i) => {
            return (
              <ProductAttributeFilter
                key={value.id}
                searchKey={"q[has_attribute_values][]"}
                visible={(i <= 4) || (listExpanded)  }
                searchState={searchState}
                attributeValue={value}
              />
            );
          })}
          {attribute?.product_attribute_values?.length > 5  && 

            <a className={`lexcms-filter-see-more ${listExpanded ? 'lexcms-active' : ''}` } onClick={( ) => setListExpanded(!listExpanded )}>{listExpanded ? 'See Less' : 'See More'} </a>
          }
        </form>
      </div>
    </div>
  );
};

export default ProductFilterAccordion;
