import { useState, useEffect } from "react";
import ProductFilterAccordion from "./product_filter_accordion";

import Image from "next/image";

const GroupedFilterAccordion = ({ attribute, searchState, initialExpandState, shouldUpdate }) => {
  const [isActive, setIsActive] = useState(initialExpandState);
  useEffect(( ) => {
    setIsActive(initialExpandState)
  }, [shouldUpdate])

  return (
    <div className="lexcms-product-search-filter-accordion">
      <div onClick={() => setIsActive(!isActive)}>
        <div className="lexcms-product-search-filter-name-and-toggle">
          <h3 className="lexcms-product-search-filter-name">
            {attribute.name}
          </h3>
          <div
            className={
              "lexcms-product-search-filter-toggle" +
              (!isActive ? "" : " active-accordion-toggle")
            }
          ></div>
        </div>
      </div>

      <div className={
          "lexcms-product-search-filter-value-container lexcms-mobile-filter-value-container" +
          (!isActive ? "" : " active-accordion-container")
        } >
        <form className="lexcms-product-search-filter-form">
          {attribute?.children?.map((attribute, i) => {
            return attribute?.product_attribute_values?.length > 0 && (
              <ProductFilterAccordion
                        key={i}
                        grouped={true}
                        searchState={searchState}
                        initialExpandState={isActive}
                        attribute={attribute}
                  />
            );
          })}
        </form>
      </div>
    </div>
  );
};

export default GroupedFilterAccordion;
