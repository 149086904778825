import {useState, useEffect, useRef} from 'react'; 
import { useRouter } from "next/router";
import Qs from 'qs'; 
import stringifySearch from '@/lib/utils'

export default function SearchBuilder(props) {
  const router =  useRouter() 
  const [search, setSearch] = useState(props.initialSearch || {})
  const _props = {
    addValueAtKey: (key, value) => {
      setSearch((prevSearch) => { 
        delete prevSearch.page
         return   {...prevSearch, [key]: value  }
          }
      )
    }, 
    removeKey: (key) => {
      setSearch((prevSearch) => { 
        delete prevSearch[key]
        return   {...prevSearch  }
          }
      )
    }, 
    setPage: (value) => {
      setSearch(prevSearch => ({...prevSearch, ['page']: value  }))
    },

     addToValuesAtKey: (key, value) => {
      setSearch(prevSearch => {
        let values = [].concat(prevSearch[key] || [])
        delete prevSearch.page
        values.push(value)
        return {...prevSearch, [key]: values}
      }
      )
    },
    removeFromValuesAtKey: (key, value) => {
      setSearch(prevSearch => {
        let values = [].concat(prevSearch[key] || [])
        delete prevSearch.page
        values = values.filter((d, i) => d !== value  )

        return {...prevSearch, [key]: values}
       })  
    },

    clearAll: () => {
      setSearch(prevSearch => ({}))
    },
    search: search, 
    humanFriendlySearch: ( ) => {
      let newObj = {} 
      Object.keys(search).forEach((key, i)  => {
        newObj[key.replace('q[', '').replace('[]', '').replace(']', '')] = search[key]
      })

      return newObj
    }, 
    triggerSearch: (pageChange) => ( props.search &&  props.search(search, pageChange) )
  }


  const prevSelected = useRef()
  useEffect(() => {
    if (typeof prevSelected.current !== 'undefined' ){
      let pageChange = false;
      if (search['page'] && prevSelected.current['page']  !==  search['page'] ){
        pageChange = true;
      }
      _props.triggerSearch(pageChange)
    if (window && router){
      let s =  _props.humanFriendlySearch( )
      let str = Object.keys(s).length > 0 ?  '?' + stringifySearch(s) : '';

      window.history.pushState('', '', window.location.pathname.replace(/\?.*/, '')  + str)
      }
    }

    prevSelected.current = search 
  }, [search])

  return (<> {typeof props.children === 'function' ? props.children(_props) : props.children}</>);
}
