import ProductFilterAccordion from "@/components/product_filter_accordion";
import GroupedFilterAccordion from "@/components/grouped_filter_accordion";
import {useState} from 'react'; 
export default function ProductFilterSidebar({attributeFilters, searchState, attributeFiltersPosition})  {
const [isActive, setIsActive] = useState(false);
const [expandAll, setExpandAll] = useState(attributeFiltersPosition === 'All Open')  
const [expandIncrementer, setExpandIncrementer] = useState(0) 

return (
  <>
     {attributeFilters.length > 0 && (

                  <div className="lexcms-mobile-filter-accordion-container">
                    <div className='lexcms-collapse-container'>
                      <a onClick={( ) => { setExpandAll(true); setExpandIncrementer((old) => old + 1 )} }>Expand </a> /
                      <a onClick={( ) =>  { setExpandAll(false); setExpandIncrementer((old) => old + 1 )} }>Collapse </a>
                      <small>all sections</small>
                    </div>


                    <div
                      className={"lexcms-mobile-accordion-toggle"}
                      onClick={() => setIsActive(!isActive)}
                    >
                      <div className="lexcms-mobile-accordion-title">
                        Product Attribute Filters
                      </div>
                      <div
                        className={
                          "lexcms-mobile-accordion-toggle-icon" +
                          (isActive ? "" : " active-accordion-mobile-toggle")
                        }
                      ></div>
                    </div>
                    <div
                      className={
                        "lexcms-mobile-filter-accordion" +
                        (isActive ? " active-outer-accordion" : "")
                      }
                    >
                      <div className="lexcms-product-search-filter-sidebar-container">
                        {attributeFilters.map((attribute, i) => {
                          return attribute.children ? (
                            <GroupedFilterAccordion
                              key={i}
                              searchState={searchState}
                              attribute={attribute}
                              shouldUpdate={expandIncrementer}
                              initialExpandState={ expandAll  }

                            />
                          ) : (
                            <ProductFilterAccordion
                              key={i}
                              searchState={searchState}
                              shouldUpdate={expandIncrementer}
                              attribute={attribute}
                              initialExpandState={expandAll}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
  </>
  )
}
