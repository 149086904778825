
export default function DropdownFilter({searchKey, options, label, className, searchState, disableFirst }) {
  const handleChange = (event) => {
    searchState.addValueAtKey(searchKey, event.target.value)
  };
  const selectedValue =  searchState.search[searchKey] || '';


  return (
    <form className={className}>
      <select aria-label={label} name={searchKey} value={selectedValue}  onChange={handleChange}>
        <option value={''} disabled={disableFirst} >
          {label}
        </option>
        {options && options.length > 0 && options.map((d, i) => {
          return (<
            option key={i} value={d?.value || d?.id} >
            {d?.label || d?.name}
          </option>
          )
        })
        }
      </select>
    </form>
  );
}
