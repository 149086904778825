import { useEffect, useRef } from "react";

export default function ProductAttributeFilter({attributeValue, searchState, searchKey, visible }) {
 const handleChange = (event) => {
   if (event.target.checked) {
     searchState.addToValuesAtKey(searchKey, event.target.value)
   }
   else {
     searchState.removeFromValuesAtKey(searchKey, event.target.value)

   }
  };
  const selectedValue =  searchState.search[searchKey] || [] ;
  const checked = selectedValue.includes(attributeValue.id.toString());


  return (
    <label className="lexcms-product-search-filter-labels" style={{'display': visible ? 'block' : 'none'}}>
        {attributeValue.raw_value} <span className="lexcms-product-search-filter-count">({attributeValue.product_count})</span>
        <input
          type="checkbox"
          name="" value={attributeValue.id || ""}
          checked={checked }
          onChange={handleChange}
          className="lexcms-product-search-filter-values"
        />
      </label>
  );
}
